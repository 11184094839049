$(function () {

    $(document).on('click','a.scroll-anchor', function (e) {
        e.preventDefault();

        var _this = $(this);

        var anchor  = _this.attr('href');
        if (anchor) {
            var $root = $('html, body');
            $root.animate({
                scrollTop: $(anchor).offset().top - 120
            }, 700);
        }
    });


});

